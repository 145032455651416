
import { LineShape } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import * as analytics from '../helpers/analytics'
import { OrganizationModule } from '../store'

@Component({
  name: 'OrganizationDiagramming'
})
export default class extends Vue {
  organizationModule = getModule(OrganizationModule, this.$store)

  updatingLineShape = false

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.$params.organizationId)!
  }

  get lineShapes () {
    return [
      {
        active: this.currentOrganization.lineShapeDefault === 'curved',
        click: () => this.updateLineShape('curved'),
        icon: '$custom-regular-wave-sine',
        id: 'curved',
        title: 'Curved'
      },
      {
        active: this.currentOrganization.lineShapeDefault === 'straight',
        click: () => this.updateLineShape('straight'),
        icon: '$custom-horizontal-rule',
        id: 'straight',
        title: 'Straight'
      },
      {
        active: this.currentOrganization.lineShapeDefault === 'square',
        click: () => this.updateLineShape('square'),
        icon: '$custom-regular-wave-square',
        id: 'square',
        title: 'Square'
      }
    ] as const
  }

  get currentLineShape () {
    return this.lineShapes.find(o => o.active)
  }

  mounted () {
    analytics.organizationDiagrammingScreen.track(this, {
      organizationId: [this.currentOrganization.id]
    })
  }

  async updateLineShape (lineShape: LineShape) {
    try {
      this.updatingLineShape = true

      await this.organizationModule.updateOrganization({
        organizationId: this.currentOrganization.id,
        props: {
          lineShapeDefault: lineShape
        }
      })
    } finally {
      this.updatingLineShape = false
    }
  }
}
